import React, { useEffect } from "react";

const SlashdotBadge = () => {
    useEffect(() => {
        // Load the first badge script
        const sfScript = document.createElement("script");
        sfScript.src = "https://b.sf-syn.com/badge_js?sf_id=3561916&variant_id=sf";
        sfScript.async = true;
        document.getElementsByTagName("head")[0].appendChild(sfScript);

        // Load the second badge script
        const sdScript = document.createElement("script");
        sdScript.src = "https://b.sf-syn.com/badge_js?sf_id=3561916&variant_id=sd";
        sdScript.async = true;
        document.getElementsByTagName("head")[0].appendChild(sdScript);

        // Cleanup function to remove the scripts when component unmounts
        return () => {
            document.getElementsByTagName("head")[0].removeChild(sfScript);
            document.getElementsByTagName("head")[0].removeChild(sdScript);
        };
    }, []);

    return (
        <>
        <h2 className="text-[30px] leading-[36px] text-[#333333] font-bold mx-10 mb-10 font-work-sans capitalize
                       md:text-[44px] md:leading-[53px] ">Proudly Verified</h2>
        <div className="flex  gap-10 md:gap-20 mb-20 mx-10">
           
            <div className="sf-root w-36" data-id="3561916" data-badge="light-default" data-variant-id="sf">
                <a href="https://sourceforge.net/software/product/FusionPOS/" target="_blank" rel="noopener noreferrer">
                    FusionPOS Reviews
                </a>
            </div>

            <div className="sf-root w-36" data-id="3561916" data-badge="users-love-us-new-white" data-variant-id="sd">
                <a href="https://slashdot.org/software/p/FusionPOS/" target="_blank" rel="noopener noreferrer">
                    FusionPOS Reviews
                </a>
            </div>
        </div>
        </>
    );
};

export default SlashdotBadge;
