import { Link } from "react-router-dom";
import logo from "./img/fusion_pos_logo_desktop.webp";
import flag from "./img/product/1.png";
import shoppingBag from "./img/product/1.png";
import mobileFPOS from "./img/fpos_mobile.webp";
import { FaAngleDown, FaAngleRight, FaAngleUp } from "react-icons/fa6";
import { useState } from "react";
import product1 from "./img/product/1.png";
import product2 from "./img/product/2.png";
import product3 from "./img/product/3.png";
import product4 from "./img/product/4.png";
import product5 from "./img/product/5.png";
import product6 from "./img/product/6.png";
import product7 from "./img/product/7.png";
import product8 from "./img/product/8.png";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { FaPhoneAlt } from "react-icons/fa";

const Navbar = () => {
  const [dropdown, setDropdown] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = (menu) => {
    setDropdown(menu);
  };
  const handleMouseLeave = () => {
    setDropdown(null);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleProductsDropdown = () => {
    setIsProductsOpen(!isProductsOpen);
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    toggleMenu();
  };

  const productData = [
    {
      img: product1,
      title: "Point Of Sale",
      desc: "POS & multi-channel ordering",
      link: "/point-of-sales",
    },
    {
      img: product2,
      title: "Reservation",
      desc: "MuLti-location and Franchise management platform ",
      link: "/restaurant-reservation-system",
    },
    {
      img: product3,
      title: "QR Ordering",
      desc: "Increase average table value by 30%",
      link: "/qr-ordering-system-for-restaurants",
    },
    {
      img: product4,
      title: "Payment Terminal",
      desc: "Low cost, non-contracted payments",
      link: "/contactless-dining",
    },
    {
      img: product5,
      title: "Online Ordering",
      desc: "Branded websites and mobile apps",
      link: "/online-ordering-website",
    },

    {
      img: product6,
      title: "Delivery Management",
      desc: "Delivery management and driver tracking",
      link: "/delivery-management",
    },
    {
      img: product7,
      title: "Order Management",
      desc: "FuLt kitchen and order management",
      link: "/order-management#Preorder",
    },
    {
      img: product8,
      title: "Online Reservation",
      desc: "Keep your customers in loop",
      link: "/online-reservation",
    },
  ];

  return (
    <div className="fixed left-0 top-0  right-0 z-20 w-full mx-auto ">
      <nav
        className={`shadow-[5px_5px_20px_#D6D6D6]  relative z-50 hidden lg:block 
                    px-2 transition-all duration-200 ease-linear bg-white  ${dropdown ? "h-[480px] xl:h-[400px]" : "h-[80px] "
          }`}
      >
        <div className="w-full flex items-center  ">
          <div className="flex items-center justify-between w-full ">
            <div className="flex items-center ">
              <Link to="/">
                <img src={logo} alt="logo" className="w-[70%] pl-3" />
              </Link>
              <div className="flex items-center gap-x-8 font-semibold ml-[50px]">
                {["Products"].map((item) => (
                  <div
                    className=""
                    onMouseEnter={() => handleMouseEnter(item)}
                    onMouseLeave={handleMouseLeave}
                    key={item}
                  >
                    <Link className="flex items-center gap-x-2 py-[28px]">
                      {item}{" "}
                      <FaAngleDown
                        className={
                          dropdown === item &&
                          "rotate-180 transition-all duration-300"
                        }
                      />
                    </Link>
                    {dropdown === item && (
                      <div className="absolute top-[80px] w-full left-0  h-full transition-all rounded-[20px] p-[20px] bg-white shadow-2xl z-50">
                        {dropdown == "Products" && (
                          <div className="w-full h-full flex justify-between">
                            <div className="w-[75%] navbar-gradient h-full rounded-[20px] p-[50px] pl-[100px]">
                              <div
                                data-aos="fade-up"
                                data-aos-duration={300}
                                className="grid grid-cols-3 gap-[55px]"
                              >
                                {productData.map((item) => (
                                  <Link
                                    to={item.link}
                                    key={item}
                                    className="flex items-center gap-x-3 w-fit"
                                  >
                                    <img
                                      className="min-w-[38px] h-[38px]"
                                      src={item.img}
                                      alt=""
                                    />
                                    <div>
                                      <p className="text-sm font-semibold pb-1">
                                        {item.title}
                                      </p>
                                      <p className="text-xs text-[#383838] font-normal">
                                        {item.desc}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                            <div className="w-[20%] h-full justify-center flex flex-col gap-4">
                              <p className="text-[#808080] font-medium">
                                QUICK LINKS
                              </p>
                              <Link
                                className="text-[#383838] font-normal"
                                to="/contactus"
                              >
                                Contact us
                              </Link>
                              <Link
                                className="text-[#383838] font-normal"
                                to="/pricing"
                              >
                                Pricing
                              </Link>

                              <Link
                                className="text-[#383838] font-normal"
                                to="/"
                              >
                                Watch demo
                              </Link>
                              <Link
                                className="text-[#383838] font-normal"
                                to="/blog"
                              >
                                Blog
                              </Link>
                              <Link
                                className="text-[#383838] font-normal"
                                to="/"
                              >
                                Fusion Hospitality
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                <Link to="/pricing">Pricing</Link>
                <Link to="/contactus">Contact Us</Link>
                <Link to="/channel-partner">Reseller</Link>
                <Link to="/blog">Blog</Link>
              </div>
            </div>

            <div className="flex items-center gap-x-6 pr-3">
              <Link
                to="/contactus"
                className="bg-[#9fe870] p-2.5 rounded-[10px] font-work-sans shadow-lg 
               font-semibold hover:bg-[#173303] hover:text-[#9fe870]"
              >
                Free Demo
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <>
        {/* menubar with options  */}
        <div
          className={
            isOpen
              ? `${`fixed top-0 right-0 flex w-full justify-between delay-300 z-50
                     duration-1000 mx-auto p-4  bg-[#173303]  lg:hidden border border-none`}`
              : `fixed top-0 right-0 flex justify-between items-center z-50
                 w-full p-2 md:px-10 lg:mx-0 bg-white lg:hidden border shadow-md`
          }
        >
          <div className="flex ">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              {isOpen ? (
                <img src={mobileFPOS} className="h-8 " alt="FusionPOS Logo" />
              ) : (
                <img
                  src={logo}
                  className="h-8 pl-2 my-2"
                  alt="FusionPOS Logo"
                />
              )}
            </Link>
          </div>
          <div className="cursor-pointer text-[#173303]">
            {isMenuOpen ? (
              <RxCross2
                onClick={toggleDrawer}
                size="32px "
                className="text-white "
              />
            ) : (
              <div>
                <Link to="tel:02030263355">
                  <FaPhoneAlt
                    size="22px"
                    className="inline mr-3  animate__animated animate__tada  animate__repeat-2"
                  />
                </Link>

                <RxHamburgerMenu
                  onClick={toggleDrawer}
                  size="32px"
                  className="inline mr-[8px] md:mr-0"
                />
              </div>
            )}
          </div>
        </div>

        {/* drawer with menubar values */}
        <div
          className={`fixed top-16 right-0 z-40 h-screen
                      overflow-y-auto transition-transform flex
                      ${isOpen
              ? "translate-x-0 ease-in-out duration-700  "
              : "translate-x-full ease-out duration-300"
            }w-[100%]`}
          tabIndex="-1"
          aria-labelledby="drawer-backdrop-label"
        >
          <div
            className={` ${isOpen
                ? " w-[20%] bg-black opacity-60 transition delay-700 duration-700 "
                : " bg-white"
              }  w-[100%]`}
          ></div>
          <div className=" overflow-y-auto  w-[80%]  bg-white">
            <ul className="flex flex-col font-semibold text-[16px] font-work-sans">
              <li className="relative  ">
                <div
                  onClick={toggleProductsDropdown}
                  className=" py-2 px-2 cursor-pointer hover:text-[#9fe870] flex justify-between items-center"
                >
                  PRODUCTS{" "}
                  {isProductsOpen ? (
                    <FaAngleUp className="animate__animated  animate__rotateInUpLeft " />
                  ) : (
                    <FaAngleRight className="inline md:ml-48 animate__animated  animate__rotateInDownLeft " />
                  )}
                </div>
                <div
                  className={`transition-max-height duration-1000 ease-in overflow-hidden ${isProductsOpen ? "max-h-screen" : "max-h-0 "
                    }`}
                >
                  <ul className="text-[#01c070] text-[14px] z-10 ">
                    <li
                      className="p-2.5 text-nowrap border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303] "
                    >
                      <Link to="/online-ordering-website">
                        Deliveries & Takeaways
                        <FaAngleRight className=" right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap  border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303]
                     "
                    >
                      <Link to="/restaurant-reservation-system">
                        Reservation
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap   border relative text-[#173303] bg-[#9fe870]
                     hover:text-[#9fe870] hover:bg-[#173303] "
                    >
                      <Link to="/qr-ordering-system-for-restaurants">
                        QR Ordering
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap   border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303]
                     "
                    >
                      <Link to="/contactless-dining">
                        Payment Terminal
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>

                    <li
                      className="p-2.5 text-nowrap border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303] "
                    >
                      <Link to="/point-of-sales">
                        Point of Sale
                        <FaAngleRight className=" right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap  border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303]
                     "
                    >
                      <Link to="/delivery-management">
                        Delivery Management
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap   border relative text-[#173303] bg-[#9fe870]
                     hover:text-[#9fe870] hover:bg-[#173303] "
                    >
                      <Link to="/order-management#Preorder">
                        Order Management
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                    <li
                      className="p-2.5 text-nowrap   border relative text-[#173303] bg-[#9fe870]
                       hover:text-[#9fe870] hover:bg-[#173303]
                     "
                    >
                      <Link to="/online-reservation">
                        Online Reservation
                        <FaAngleRight className="right-0 inline absolute mx-2 top-3" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className=" ">
                <Link
                  to="/pricing"
                  className="block py-2 px-3 relative group hover:text-[#9fe870]"
                >
                  PRICING
                </Link>
              </li>
              <li className=" ">
                <Link
                  to="/contactus"
                  className="block py-2 px-3 relative group hover:text-[#9fe870]"
                >
                  CONTACT
                </Link>
                <Link
                  className="block py-2 px-3 relative group hover:text-[#9fe870]"
                  to="/channel-partner">RESELLER</Link>
              </li>
              <li className=" ">
                <Link
                  to="/blog"
                  className="block py-2 px-3 relative group hover:text-[#9fe870]"
                >
                  BLOG
                </Link>
              </li>
              <li className=" flex justify-center mt-3 mb-3">
                <Link to="/contactus">
                  <button
                    to=""
                    className="block py-2 px-3 relative group  shadow-lg
                  hover:text-[#9fe870] hover:bg-[#173303] duration-1000
                  text-center bg-[#9fe870]  w-[150px] border rounded-full
                  animate__animated  animate__pulse
                  "
                  >
                    CALL US
                  </button>
                </Link>
              </li>
              <li className=" flex justify-center mt-3 mb-3">
                <button
                  to=""
                  className="block py-2 px-5 relative group shadow-lg duration-1000
                  text-center bg-[#9fe870] border rounded-full text-nowrap
                  hover:text-[#9fe870] hover:bg-[#173303] w-[150px] 
                  "
                >
                  Free Demo
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    </div>
  );
};

export default Navbar;
