import { useEffect, useState } from "react";
import done from "./img/tick_mark.png";
import pricingData from "../../../utils/pricingData";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PlansDigDeeper = () => {
  const [submitMessage, setSubmitMessage] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [subscriptionTypeTwo, setSubscriptionTypeTwo] = useState("monthly");
  const [activeFeature, setActiveFeature] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activePlan, setActivePlan] = useState("standard");

  const handlePrev = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
      if (activePlan === "enterprise") setActivePlan("premium");
      else if (activePlan === "premium") setActivePlan("standard");
    }
  };
  const handleNext = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
      if (activePlan === "standard") setActivePlan("premium");
      else if (activePlan === "premium") setActivePlan("enterprise");
    }
  };

  useEffect(() => {
    setActiveFeature(pricingData[1]);
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    const formData = new FormData(e.target);
    const full_name = formData.get("full_name");
    const phone_no = formData.get("phone_no");
    const business_name = formData.get("business_name");
    const email = formData.get("email");
    const inquery = formData.get("inquery");
    console.log(full_name, phone_no, business_name, email, inquery);
    setSubmitMessage("Form submitted successfully!");
  };
  return (
    <div>
      <HeaderContainer>
        <div
          id="deeper-pricing-section"
          className="mb-[40px] lg:mb-[100px] mx-5"
        >
          <h3 className="text-center lg:text-left text-3xl lg:text-5xl font-bold mb-12 font-work-sans">
            Want to know more?{" "}
            <span className="text-primary">Let&apos;s dig deeper</span>
          </h3>

          {/* large device */}
          <div className="hidden lg:grid grid-cols-5 items-end pb-5 mb-10 border-b-[0.5px] border-primary font-work-sans">
            <div className="col-span-2">
              <div
                className="w-fit bg-[#9FE870] rounded-full p-2 flex gap-2 mb-12 font-work-sans"
                style={{
                  boxShadow: "inset 9px 9px 15px 1px rgba(0,0,0,0.2)",
                }}
              >
                <button
                  onClick={() => setSubscriptionTypeTwo("monthly")}
                  className={`${subscriptionTypeTwo === "monthly"
                      ? "bg-white rounded-full shadow-lg"
                      : "bg-none"
                    } px-10 py-2.5 text-[#1A1A1A] transition-all duration-200 hover:shadow-lg font-medium
                   font-work-sans hover:bg-white hover:rounded-full min-w-fit`}
                >
                  Weekly
                </button>
                <button
                  onClick={() => setSubscriptionTypeTwo("yearly")}
                  className={`${subscriptionTypeTwo === "yearly"
                      ? "bg-white rounded-full shadow-lg"
                      : "bg-none"
                    } px-10 py-2.5 text-[#1A1A1A] transition-all duration-200 hover:shadow-lg  font-medium
                   hover:bg-white hover:rounded-full min-w-fit`}
                >
                  Yearly (save 18%)
                </button>
              </div>
              <p className="font-bold text-[24px]">Feature List</p>
            </div>

            <div>
              <h4 className="font-bold text-3xl leading-[60px] font-work-sans">
                Starter
              </h4>
              <p>
                <span className="text-primary">
                  {subscriptionTypeTwo === "monthly" ? (
                    <>
                      £15.99 <span className="text-sm">+ VAT</span>
                    </>
                  ) : (
                    <>
                      £ 959 <span className="text-sm">+ VAT</span>
                    </>
                  )}
                </span>{" "}
                / per {subscriptionTypeTwo === "monthly" ? "week" : "year"}
              </p>
            </div>
            <div>
              <h4 className="font-bold text-3xl leading-[60px] font-work-sans">
                Standard
              </h4>
              <p>
                <span className="text-primary">
                  {subscriptionTypeTwo === "monthly" ? "£ 23.99 + VAT" : "£1259 + VAT"}
                </span>{" "}
                / per {subscriptionTypeTwo === "monthly" ? "week" : "year"}
              </p>
              <span className="bg-primary text-black inline-block mt-3 py-1.5 px-3">
                Most Popular
              </span>
            </div>
            <div>
              <h4 className="font-bold text-3xl leading-[60px] font-work-sans">
                Premium
              </h4>
              <p>
                <span className="text-primary">
                  {subscriptionTypeTwo === "monthly" ? "£39.99" : "£1599.00"}
                </span>{" "}
                / per {subscriptionTypeTwo === "monthly" ? "week" : "year"}
              </p>
            </div>
          </div>
          <div className="hidden lg:grid grid-cols-5 gap-5 font-work-sans">
            <div className="space-y-8">
              {pricingData.map((item) => (
                <div
                  key={item.id}
                  onClick={() => setActiveFeature(item)}
                  className={`w-fit pb-[18px] cursor-pointer ${activeFeature?.id === item.id
                      ? "font-semibold border-b-[3px] border-primary"
                      : "border-b-[0.5px] border-[#ffffff]/50"
                    }`}
                >
                  {item.feature}
                </div>
              ))}
            </div>
            <div className="col-span-4 space-y-[51px]">
              {activeFeature?.subFeatures?.map((subFeature) => (
                <div key={subFeature?.id} className="grid grid-cols-4">
                  <p className="font-semibold">{subFeature?.name}</p>
                  <div className="px-12">
                    {subFeature?.standard ? (
                      <LazyLoadImage src={done} alt={done} className="w-5" />
                    ) : (
                      <span className="block w-6 text-center">-</span>
                    )}
                  </div>
                  <div className="px-12">
                    {subFeature?.premium ? (
                      <LazyLoadImage src={done} alt={done} className="w-5" />
                    ) : (
                      <span className="block w-6 text-center">-</span>
                    )}
                  </div>
                  <div className="px-12">
                    {subFeature?.enterprise ? (
                      <LazyLoadImage src={done} alt={done} className="w-5" />
                    ) : (
                      <span className="block w-6 text-center">-</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* small device */}
          <div
            className="lg:hidden w-fit mx-auto bg-[#9FE870] rounded-full p-2 flex gap-2 mb-8"
            style={{ boxShadow: "inset 9px 9px 15px 1px rgba(0,0,0,0.2)" }}
          >
            <button
              onClick={() => setSubscriptionTypeTwo("monthly")}
              className={`${subscriptionTypeTwo === "monthly"
                  ? "bg-white rounded-full shadow-lg"
                  : "bg-none"
                } px-8 py-2 text-[#1A1A1A] transition-all duration-200 font-work-sans font-bold hover:shadow-lg hover:bg-white hover:rounded-full min-w-fit`}
            >
              Monthly
            </button>
            <button
              onClick={() => setSubscriptionTypeTwo("yearly")}
              className={`${subscriptionTypeTwo === "yearly"
                  ? "bg-white rounded-full shadow-lg"
                  : "bg-none"
                } px-8 py-2 text-[#1A1A1A] transition-all duration-200 hover:shadow-lg font-work-sans  font-bold
               hover:bg-white hover:rounded-full min-w-fit`}
            >
              Yearly
            </button>
          </div>

          <div className="flex lg:hidden gap-5 justify-between items-end pb-4 mb-8 border-b-[0.5px] border-primary">
            <p>Feature List</p>
            <div className="w-2/3 gap-7 flex items-end">
              <button onClick={handlePrev}>
                <svg
                  width="11"
                  height="20"
                  viewBox="0 0 11 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.18164 1L1.61134 9.32733C1.2646 9.70875 1.2646 10.2913 1.61134 10.6727L9.18164 19"
                    stroke="#EAEAEA"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <Swiper
                slidesPerView="auto"
                effect={"fade"}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                allowTouchMove={false}
                simulateTouch={false}
              >
                <SwiperSlide>
                  <div className="mt-12">
                    <h4 className="font-bold text-3xl leading-[60px]">
                      Standard
                    </h4>
                    <p>
                      <span className="text-primary">
                        {subscriptionTypeTwo === "monthly"
                          ? "£55.00"
                          : "£550.00"}
                      </span>{" "}
                      / per{" "}
                      {subscriptionTypeTwo === "monthly" ? "month" : "year"}
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <h4 className="font-bold text-3xl leading-[60px]">
                      Premium
                    </h4>
                    <p>
                      <span className="text-primary">
                        {subscriptionTypeTwo === "monthly"
                          ? "£75.00"
                          : "£750.00"}
                      </span>{" "}
                      / per{" "}
                      {subscriptionTypeTwo === "monthly" ? "month" : "year"}
                    </p>
                    <span className="bg-primary text-black inline-block mt-3 py-1.5 px-3">
                      Most Popular
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="mt-12">
                    <h4 className="font-bold text-3xl leading-[60px]">
                      Enterprise
                    </h4>
                    <p className="text-primary">Speak to sales</p>
                  </div>
                </SwiperSlide>
              </Swiper>
              <button onClick={handleNext}>
                <svg
                  width="10"
                  height="20"
                  viewBox="0 0 10 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L8.5703 9.32733C8.91704 9.70875 8.91704 10.2913 8.5703 10.6727L1 19"
                    stroke="#EAEAEA"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex lg:hidden gap-2">
            <div className="w-[55%] space-y-8">
              {pricingData.map((item) => (
                <div
                  key={item.id}
                  onClick={() => setActiveFeature(item)}
                  className={`w-fit pb-[18px] text-sm cursor-pointer ${activeFeature?.id === item.id
                      ? "font-semibold border-b-[3px] border-primary"
                      : "border-b-[0.5px] border-[#ffffff]/50"
                    }`}
                >
                  {item.feature}
                </div>
              ))}
            </div>
            <div className="w-[45%] space-y-8">
              {activeFeature?.subFeatures?.map((subFeature) => (
                <div
                  key={subFeature?.id}
                  className="grid grid-cols-5 gap-2 items-center"
                >
                  <p className="font-semibold col-span-4 text-sm">
                    {subFeature?.name}
                  </p>
                  <div>
                    {subFeature?.[activePlan] ? (
                      <LazyLoadImage src={done} alt="" className="w-5" />
                    ) : (
                      <span className="block w-6 text-center">-</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default PlansDigDeeper;
